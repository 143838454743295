<template>
    <div class="flex-1 min-h-0 overflow-y-auto divide-y-2 divide-default">
        <template v-for="(menuItem, menuItemIndex) in menu">
            <template v-if="!menuItem.permissions || (menuItem.permissions && $me.hasPermission(menuItem.permissions))">
                <router-link :key="'menuItem_' + menuItemIndex" v-slot="{ isActive, href, navigate }" :to="menuItem.route" custom>
                    <a :href="href" :class="isActive ? 'bg-slate' : ''" class="flex p-default hover:bg-slate group" aria-current="page" @click="navigate">
                        <i :class="(isActive ? 'text-primary-500' : 'text-gray-100') + ' ' + menuItem.icon" class="text-gray-400 group-hover:text-primary-500 pt-0.5" />
                        <div class="ml-3 text-sm">
                            <p :class="isActive ? 'text-primary-500' : ''" class="font-medium text-gray-300 group-hover:text-primary-500">{{ menuItem.label }}</p>
                            <p class="mt-1 text-gray-400">{{ menuItem.description || "Menu Item Description." }}</p>
                        </div>
                    </a>
                </router-link>
            </template>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        menu: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    data() {
        return {
            baseClasses: "",
        };
    },
    computed: {
        attributes() {
            return {
                class: this.classes,
                ...this.$attrs,
            };
        },
        classes() {
            return `section-links ${this.baseClasses}`;
        },
    },
    mounted() {
        this.$forceUpdate();
    },
};
</script>
